import React, { useLayoutEffect, useState} from "react";

import "../App.css";

import loadPayments, { updatePaymentButtons} from 'payments-ui-components/components/CheckoutButtons'
import { CreateHostedSessionResult, OnClickResult, PaymentButtons, CreateIntentRequest, CreateIntentResult, Environment } from "payments-ui-components/components/types";
import { getToken } from "src/workers/common-worker";
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import GooglePayButton from "@google-pay/button-react";

export default function Test() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const update = ()=> {
        updatePaymentButtons({
            amount: 300, 
            currency: "gbp", 
            description: "novo",
            additionalInfo: "additional 2",
        }, "pt-PT", null);
    }

    const createIntent = async(r: CreateIntentRequest | undefined) => {
        let token = await getToken("https://api.staging.truphone.com/payments/paywall-auth")

        let req = {
            amount: 200,
            currency: "EUR",
            customer_id: "nunom_336",
            description: "1G11233654349_2",
            capture_method:"automatic",
            success_url: r?.successUrl,
            failure_url: r?.failureUrl,
            instrument: r?.instrument ? {
                type: r.instrument.type,
                msisdn: r.instrument.msisdn
            } : null
        };
        
        let response = await fetch(`https://api.staging.truphone.net/payments/payments-service/v1/intents`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "tenant-id": "connect.integration-accelerator"
            },
            body: JSON.stringify(req)
        });

        if(response.status !== 201 && response.status !== 202){
            throw await response.json();
        }
        
        let result = await response.json();
        console.log(result);
        let intent: CreateIntentResult = {
            id: result.id,
            clientSecret: result.client_secret,
            redirectUrl: ""
        }

        if(result._links && result._links.redirect){
            intent.redirectUrl = result._links.redirect.href;
        }
        return intent;
    }

    useLayoutEffect( () => {
        console.log("Page Loaded");
        let constructPaywallButtons = {
            createIntent: async(r: CreateIntentRequest | undefined) => {
                return await createIntent(r);
            },
            createHostedSession: () => {
                return fetch("https://nuno-test.free.beeceptor.com/test").then((res) => res.json()).then(res => {
                    return {
                        redirectUrl: res.redirect_url
                    } as CreateHostedSessionResult
                })
            },
            onClick: (ob: OnClickResult) => {
                return Promise.resolve(ob).then(() => console.log("clicked" + ob.paymentButton));
            },
            onComplete: () => {
                return Promise.resolve(null).then(() => console.log("completed"));
            },
            onError : (message) => {
                console.log(message);
                
                return Promise.resolve(null).then(() => {navigate("/msisdn/fail")});
            },
            paymentRequest: {
                currency: "gbp",
                description: "prod 1",
                additionalInfo: "teste aditional",
                amount: 100
            },
            locale: "en-GB",
            tenant: "connect.integration-accelerator",
            brand: "connect.integration-accelerator",
            allowedPaymentButtons: [PaymentButtons.Wallet, PaymentButtons.ZahlEinfach],
            testMode: true,
            customerId: "test_04062024"
        } 

        
        loadPayments(".buttons", constructPaywallButtons, Environment.Staging)
    });

    return (
        <div className="top-margin">
            <button onClick={() => update()}>Plan 2</button>
            <div className="buttons"></div>
            <div>TEST GOOGLE SDK</div>
            <GooglePayButton
                environment="TEST"
                paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                    {
                        type: 'CARD',
                        parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: ['MASTERCARD', 'VISA'],
                        },
                        tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        parameters: {
                            gateway: 'example',
                            gatewayMerchantId: 'exampleGatewayMerchantId',
                        },
                        },
                    },
                    ],
                    merchantInfo: {
                    merchantId: '12345678901234567890',
                    merchantName: 'Demo Merchant',
                    },
                    transactionInfo: {
                    totalPriceStatus: 'FINAL',
                    totalPriceLabel: 'Total',
                    totalPrice: '100.00',
                    currencyCode: 'USD',
                    countryCode: 'US',
                    },
                }}
                onLoadPaymentData={paymentRequest => {
                    console.log('load payment data', paymentRequest);
                }}
                />
        </div>
    );
}