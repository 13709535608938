import React, {useState, useEffect, useMemo, Suspense, useLayoutEffect } from "react";
import { useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {validate, ValidationError} from "class-validator";
import config from "src/config";
import {
    InitInvoiceRequest,
    InitResponse,
    Nullable
} from "src/workers/types";
import { dispatch } from "src/workers/common-worker";
import { Trans, useTranslation } from 'react-i18next';
import {Assets, Branding, PresentationSettings} from "src/domain/PresentationSettings";
import {IntentsList, NotificationPaymentStatus} from "src/features/enterprise/intents/types/IntentsList";
import { Intent } from "src/features/enterprise/intents/types/Intent";
import { Button, Grid, alpha, decomposeColor, hexToRgb } from "@mui/material";
import formatCurrency from "src/domain/CurrencyFormatter";
import 'src/features/enterprise/styles/enterprise.css'
import InvoiceForm from "./InvoiceForm";
import { IntentPage } from "src/features/enterprise/intents/types/IntentPage";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementLocale, loadStripe } from "@stripe/stripe-js";
import { Instrument } from "src/features/enterprise/instruments/types/Instrument";

export default function InvoicesList() {
    const { t, i18n } = useTranslation();
    const [queryListParameter, setQueryListParameter] = useState<string>();
    const [invoiceNameSucceeded, setInvoiceNameSucceeded] = useState<string>();
    const [showPaymentSucceededNotification, setShowPaymentSucceededNotification] = useState<boolean>(false);
    const [showPaymentProcessingNotification, setShowPaymentProcessingNotification] = useState<boolean>(false);
    const [successSetup, setSuccessSetup] = useState<boolean>(false)
    const [showSuccessSetupNotification, setShowSuccessSetupNotification] = useState<boolean>(false)
    const [hasAutomatedPayments, setHasAutomatedPayments] = useState<boolean>(false)
    const [intentsList, setIntentsList] = useState<IntentsList>();
    const [intentPageData, setIntentPageData] = useState<IntentPage | null>();
    const [brandID, setBrandID] = useState<string>("");
    const [searchParams] = useSearchParams()
    const [gatewayAccount, setGatewayAccount] = useState("");
    const navigate = useNavigate();
    const location = useLocation()
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
    const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl
    const worker: Worker = useMemo(
        () => new Worker(new URL("src/features/enterprise/intents/workers/init-invoices-worker.ts", import.meta.url)),
        []
    );
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>|null>(null);

    const automatedPaymentsInitialDate = new Date('2024-01-05').toLocaleDateString(navigator.language);

    useLayoutEffect( () => {
        setStripePromise(loadStripe(config.gateway.apiKeys[gatewayAccount], {locale: (intentsList?.locale as StripeElementLocale)}));
        if(intentsList !== undefined){
            setBackgroundColor(intentsList)
            loadFavicon(intentsList.presentationSettings.assets.favicon);
        }

    }, [intentsList, gatewayAccount])

    useEffect(() => {
        let qParameter = searchParams.get("q");

        if(isNotLoggedIn(qParameter)) {
            let redirectUrl = `/login?t=U2FsdGVkX19rdTiOaDFKRr5%2BzNplfTiveabRLjw%2F5AA%3D`;
            navigate(redirectUrl);
            return;
        }
        
        if(qParameter === null || qParameter === ""){
            if (location.state.invoicesList !== null) {
                setQueryListParameter(location.state.invoicesList.config_id)
                buildInvoicePage(location.state.invoicesList)
            }
            return
        }

        if (Worker) {
            const request: InitInvoiceRequest = {
                tokenMediatorUrl: tokenMediatorUrl,
                paymentsServiceUrl: paymentsServiceUrl,
                configId: encodeURIComponent(qParameter)
            };

            setQueryListParameter(qParameter);

            dispatch<InitInvoiceRequest, InitResponse>(worker, request)
                .then((data: Nullable<InitResponse>) => buildInvoicePage(data))
                .then((errors) => setValidationErrors(errors));
        }

        let is = searchParams.get("instrument_setup");
        if ((is !== null && is === "succeed") || (location.state?.instrumentSetup)) {
            setSuccessSetup(true);
            setHasAutomatedPayments(true);
            setShowSuccessSetupNotification(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worker, searchParams, paymentsServiceUrl, tokenMediatorUrl]);

    function isNotLoggedIn(qParameter : string | null) : boolean {
        return (qParameter === null || qParameter === "") && (location.state === null || location.state.invoicesList === null || location.state.invoicesList === undefined);
    }

    const setBackgroundColor = (intentsList: IntentsList) => {
        document.body.style.backgroundColor = intentsList.presentationSettings.branding.background_color;
    }

    const loadFavicon = (faviconLink: string | undefined) => {
        if (faviconLink === undefined) {
            return;
        }
        
        const link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
    
        (link as HTMLLinkElement).href = faviconLink;
    }

    const onSelectInvoiceClickHandler = (intent) => {
        if(hasAutomatedPayments){
            console.log("disabled");
            return;
        }

        let intentPage = new IntentPage();
        intentPage.tenantId = intentsList?.tenantId!;
        intentPage.customerId = intentsList?.customerId!;
        intentPage.intent = intent;
        intentPage.presentationSettings = intentsList?.presentationSettings!;
        intentPage.locale = intentsList?.locale!;
        intentPage.returnUrl = queryListParameter!;
        intentPage.successCallBack = successCallback;
        intentPage.pageBack = pageBack;
        intentPage.instrumentTypes = intentsList?.instrumentTypes || [];
        setIntentPageData(intentPage);
    }

    const closePaymentSucceededNotification = () => {
        setShowPaymentSucceededNotification(false);
    }

    const pageBack = () => {
        setIntentPageData(null);
    }

    const successCallback = (intentDescription, status: NotificationPaymentStatus) => {
        let description = decodeURIComponent(intentDescription);
        if(intentsList){
            setHasAutomatedPayments(true);

            if(status === NotificationPaymentStatus.Processing){
                setShowPaymentProcessingNotification(true);
            }
            
            if(status === NotificationPaymentStatus.Paid){
                setShowPaymentSucceededNotification(true);
            }

            setInvoiceNameSucceeded(description);
            setTimeout(() => {
                closePaymentSucceededNotification();
            }, 3000)
        }

        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("q", queryListParameter!)
        navigate('?' +searchParams.toString())

        setIntentPageData(null);
    }

    const openPaymentSettings = () => {
        navigate("/invoices/settings", {
            state:{
                t: intentsList?.tenantId, 
                b: brandID,
                c: intentsList?.customerId, 
                i: intentsList?.directDebitInstrument,
                q: queryListParameter,
                ps: intentsList?.presentationSettings,
                it: intentsList?.instrumentTypes,
                ga: gatewayAccount,
            }
        });
    }

    async function buildInvoicePage(data) : Promise<ValidationError[]> {
        let intentsList = new IntentsList();

        data.pending_deferred_intents.forEach(item => mapIntent(item, intentsList));
        mapSettings(data, intentsList);
        intentsList.directDebitInstrument = mapInstrument(data.direct_debit_instrument);
        
        intentsList.tenantId = data.tenant_id;
        intentsList.customerId = data.customer_id;

        if(!hasAutomatedPayments){
            setHasAutomatedPayments(data.has_automated_payments);
        }

        intentsList.instrumentTypes = data.instrument_types;
        setGatewayAccount(data.gateway_account);
        const errors: ValidationError[] = await validate(intentsList, { skipMissingProperties: true });

        setBrandID(data.brand_id)

        if (errors.length > 0) {
            return errors;
        }

        setLocale(intentsList);
        setIntentsList(pi => ({...intentsList}));

        await i18n.changeLanguage(mapLocale(intentsList.locale));
        document.title = intentsList.presentationSettings.display_name;
        
        return errors;
    }

    function setLocale(intentsList: IntentsList){
        let brwLocale = window.navigator.language;

        if(brwLocale !== null && brwLocale !== undefined && brwLocale !== ""){
            intentsList.locale = brwLocale;
            return; 
        }

        intentsList.locale = "en";

        return;
    }

    function mapIntent(item, intentsList: IntentsList){
        let i = new Intent();
            i.amount = item.amount;
            i.client_secret = item.client_secret;
            i.created_stamp = item.created_stamp;
            i.currency =item.currency;
            i.description = item.description;
            i.id = item.id;
            i.brand_id = item.brand_id;
            intentsList.intents.push(i);
    }

    function mapSettings(data, intentsList: IntentsList){
        let presentationSettings = new PresentationSettings();
        let assets = new Assets();
        assets.logo = data.presentation_settings.assets.logo;
        assets.favicon = data.presentation_settings.assets.favicon;

        let branding = new Branding();
        branding.button_color = data.presentation_settings.branding.button_color;
        branding.background_color = data.presentation_settings.branding.background_color;
        presentationSettings.assets = assets;
        presentationSettings.branding = branding;
        presentationSettings.display_name = data.presentation_settings.display_name;
        presentationSettings.terms_of_service_url = data.presentation_settings.terms_of_service_url;
        presentationSettings.privacy_policy_url = data.presentation_settings.privacy_policy_url;
        intentsList.presentationSettings = presentationSettings;
    }

    function mapInstrument(data) : Nullable<Instrument> {
        if (data == null) {
            return null;
        }

        let instrument = {} as Instrument;
        instrument.type = data.type;
        instrument.createdStamp = new Date(data.created_stamp);
        instrument.last4 = data.last4;
        instrument.status = data.status;
        return instrument;
    }

    const mapLocale = (locale: string) : string => {
        if (locale === "zh") {
            return "zh_Hans";
        }
        if (locale === "zh-HK") {
            return "zh_Hant_HK";
        }
        if (locale === "nb") {
            return "nb_NO";
        }

        return (locale as string).replace("-", "_");
    }

    const isSuccessRedirect = (): [boolean, string, NotificationPaymentStatus] => {
        let paymentSucceeded = searchParams.get("succeeded");
        searchParams.delete("succeeded");
        if(paymentSucceeded === null || paymentSucceeded === ""){
            return [false, "", NotificationPaymentStatus.Pending];
        }
        
        return [true, paymentSucceeded, NotificationPaymentStatus.Processing];
    }

    const shouldShowInvoiceList = (): boolean => {
        if(intentsList !== undefined){
            return !hasAutomatedPayments && !successSetup;
        }
        
        return false;
    }

    const shouldHidePaymentSettingsButton = (): boolean => {
        return ((hasAutomatedPayments || successSetup) && !intentsList?.directDebitInstrument) || intentsList?.directDebitInstrument?.status === "setup_requested";
    }

    if (intentsList === null || intentsList === undefined) {
        return (<div><div className="lds-dual-ring"></div></div>);
    }

    if(validationErrors.length > 0){
        return (<div></div>)
    }

    let [ok, paid, status] = isSuccessRedirect();
    if(ok){
        successCallback(paid, status);
    }

    const appearance = {
        variables: {
            fontFamily: 'Roboto, Ideal Sans, system-ui, sans-serif',
            spacingUnit: '4px',
            borderRadius: '4px',
            fontSizeBase: '16px',
            colorText: intentsList?.presentationSettings.branding.button_color,
            colorTextPlaceholder:'#757680'
        }
    };
    const options = {
        clientSecret: intentPageData?.intent?.client_secret,
        appearance
    };

    return (
        <div style={{color: intentsList?.presentationSettings.branding.button_color}} className="page">
            <div className="page-header"><div>{intentsList?.customerId}</div></div>

            {intentPageData &&(
                <Elements options={options} stripe={stripePromise}>
                    <InvoiceForm {...intentPageData}></InvoiceForm>
                </Elements>
            )}

            {!intentPageData &&(
                <Grid container direction="row" justifyContent="center">
                    
                    <Grid item xs={12} sm={12} md={8} className="xs-centered" container alignItems="center" justifyContent="space-between">
                        <div hidden={intentsList.tenantId === ""}>
                            <img className="branding-logo" width="auto" height="auto" src={intentsList?.presentationSettings.assets.logo} alt="brandingLogo"></img>
                        </div>
                        <div hidden={intentsList.tenantId === "" || intentsList.customerId === "" || brandID === "" || shouldHidePaymentSettingsButton()}>
                            <Button onClick={openPaymentSettings} className="defaultButton" style={{float:"right", backgroundColor: intentsList?.presentationSettings.branding.button_color}} variant="contained">{t("paymentSettings")} <span className="button-icon material-symbols-outlined">settings</span></Button>
                        </div>
                        
                    </Grid>

                    {showSuccessSetupNotification &&
                        <Grid item xs={12} sm={12} md={8} className="xs-centered">
                            <Grid item xs={12} sm={12} md={12} className="xs-centered notification-success">
                                <span onClick={(e) => setShowSuccessSetupNotification(false)} className="material-symbols-outlined">close</span>
                                <span>{t("instrumentSetupSuccess")}</span>
                            </Grid>
                        </Grid>
                    }
                    
                    {showPaymentSucceededNotification &&
                        <Grid item xs={12} sm={12} md={8} className="xs-centered">
                            <Grid item xs={12} sm={12} md={12} className="xs-centered notification-success">
                                <span onClick={(e) => closePaymentSucceededNotification()} className="material-symbols-outlined">close</span>
                                <Trans i18nKey="invoiceSuccessfullyPaid" values={{ name: invoiceNameSucceeded}} />
                            </Grid>                
                        </Grid>
                    }

                    {showPaymentProcessingNotification &&
                        <Grid item xs={12} sm={12} md={8} className="xs-centered">
                            <Grid item xs={12} sm={12} md={12} className="xs-centered notification-warning">
                                <span onClick={(e) => closePaymentSucceededNotification()} className="material-symbols-outlined">close</span>
                                <Trans i18nKey="invoicePaymentProcessing" values={{ name: invoiceNameSucceeded}} />
                            </Grid>                
                        </Grid>
                    }

                    {shouldShowInvoiceList() &&
                        <Grid item xs={12} sm={12} md={8} className="page-list">
                            <h1 style={{textAlign: "left"}}>{t("invoiceList")}</h1>
                            
                            <Grid item xs={12} sm={12} container direction="row" className="page-list-content">
                                <Grid item xs={6} sm={6} md={6} className="page-list__header">
                                    <span>{t("invoiceNumber")}</span>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} className="page-list__header">
                                    <span>{t("invoiceValue")}</span>
                                </Grid>

                                {intentsList?.intents.map(i => (
                                    <Grid key={i.id} item className="page-list__item" xs={12} container direction="row">
                                        <div onClick={(e) => onSelectInvoiceClickHandler(i)} className="list-item-action" style={{"--colorrgb": decomposeColor(intentsList.presentationSettings.branding.button_color).values.join(","),backgroundColor: alpha(intentsList.presentationSettings.branding.button_color, 0.0)} as React.CSSProperties}>
                                            <Grid item xs={6} sm={6} md={6} >
                                                {i.description}
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} >
                                                {formatCurrency(intentsList.locale, i.currency, (i.amount))}
                                            </Grid>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>

                            <div hidden={intentsList?.intents !== undefined && intentsList?.intents.length > 0 &&
                                intentsList.intents.length > 0}>
                                <Grid item className="list-empty" container xs={12} direction="row" alignItems="stretch">
                                    <Grid item xs={12} >
                                        <span>{t("noInvoices")}</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    }

                    {!shouldShowInvoiceList() && 
                        <Grid style={{backgroundColor: intentsList?.presentationSettings.branding.button_color}} item xs={12} sm={12} md={8} className="xs-centered notification-direct-debit">
                            {t("automatedPaymentsNotification")}
                            <hr></hr>
                            <span>
                                <Trans
                                    i18nKey="automatedPaymentsExtendedNotificationPart1"
                                    shouldUnescape={true}
                                    values={{ initial_date: automatedPaymentsInitialDate}}
                                />
                            </span>
                            <br/>
                            <span>
                                <Trans
                                    i18nKey="automatedPaymentsExtendedNotificationPart2"
                                />
                            </span>
                            <br/><br/>
                            <span>
                                <Trans
                                    i18nKey="automatedPaymentsExtendedNotificationPart3"
                                />
                            </span>
                        </Grid>
                    }
                </Grid>
            )}
        </div>    
    );
}

