let token: string | null
const urlWhitelist = new RegExp('(https?:\\/\\/api.(.+?\\.)?truphone\\.com(\\/[A-Za-z0-9\\-\\._~:\\/\\?#\\[\\]@!$&\'\\(\\)\\*\\+,;\\=]*)?)');

function isWhitelistedUrl(url: string): boolean {
    return urlWhitelist.test(url);
}

async function getToken(tokenMediatorUrl: string): Promise<string | null> {
    // TODO: handle expiration?
    if (token) {
        return token;
    }

    if (!isWhitelistedUrl(tokenMediatorUrl)) {
        return null;
    }

    const response = await fetch(`${tokenMediatorUrl}/token`, {
        method: "GET",
    });

    const jsonData = await response.json();
    if (jsonData.access_token) {
        token = jsonData.access_token
    }

    return token;
}

async function dispatch<TRequest, TResponse>(worker: Worker, payload: TRequest): Promise<TResponse> {
    return new Promise((resolve) => {
        if (!worker) {
            throw new Error('Worker not available');
        }

        const eventHandler = (
            ev: MessageEvent<TResponse>
        ) => {
            const { data } = ev;
            // remove the event handler to avoid memory leaks
            worker.removeEventListener('message', eventHandler);
            resolve(data);
        };

        worker.addEventListener('message', eventHandler);
        worker.postMessage(payload);

        return null;
    });
}

export { getToken, dispatch, isWhitelistedUrl }