import { Button, Grid } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js';

import {
    ButtonsComponentPage,
    IntentChallenged,
    MsisdnUpdate,
    PaymentCompleted,
    PaymentRequestData,
    PaymentRequestFinalize,
    PaymentRequestUpdate,
    isIntentChallenged,
    isMsisdnUpdate,
    isPaymentCompleted,
    isPaymentRequestData,
    isPaymentRequestFinalize,
    isPaymentRequestUpdate, isIntentFailed, IntentFailed
} from './Types';
import { PaymentButtons as Buttons } from 'payments-ui-components/components/types';
import './Styles.css';
import { ZAHL_EINFFACH_PAYMENT_BUTTON } from 'src/features/pekunia/constants';
import { Trans, useTranslation } from 'react-i18next';

export default function PaymentButtons(buttonsComponentPage: ButtonsComponentPage) {
    const stripe = useStripe();
    const { t, i18n } = useTranslation();
    const [context, setContext] = useState<unknown>();
    const [paymentRequest, _setPaymentRequest] = useState<PaymentRequest>();
    const paymentRequestRef = useRef(paymentRequest);
    const setPaymentRequest = data => {
        paymentRequestRef.current = data;
        _setPaymentRequest(data);
    };

    const [payment, setPayment] = useState<'apple_pay' | 'google_pay' | 'hosted_session'>();
    const [layoutReady, _setLayoutReady] = useState<boolean>(false);
    const layoutReadyRef = useRef(layoutReady);
    const setLayoutReady = data => {
        layoutReadyRef.current = data;
        _setLayoutReady(data);
    };

    const paymentIntentApi = 'intent';
    const paymentSessionApi = 'session';
    const googlePayPaymentButton = 'google_pay';
    const applePayPaymentButton = 'apple_pay';
    const sessionPaymentButton = 'hosted_session';
    const [paymentButtons, _setPaymentButtons] = useState<Buttons[]>();
    const paymentButtonsRef = useRef(paymentButtons);
    const setPaymentButtons = data => {
        paymentButtonsRef.current = data;
        _setPaymentButtons(data);
    };
    const [style, setStyle] = useState<CSSProperties>();

    const secretRef = useRef("");
    const setSecret = data => {
        secretRef.current = data;
    };

    const [locale, setLocale] = useState<string>()
    const [amount, setAmount] = useState<number>()
    const [currency, setCurrency] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [additionalInfo, setAdditionalInfo] = useState<string>()
    const [isWalletCheckingFinished, setIsWalletCheckingFinished] = useState<boolean>(false);
    const [isWalletAvailable, setIsWalletAvailable] = useState<boolean>(false);
    let popup: Window|null = null;

    const messageHandler = async function (
        ev: MessageEvent<PaymentRequestData | PaymentRequestUpdate | PaymentRequestFinalize | IntentChallenged | MsisdnUpdate | PaymentCompleted>
    ) {
        if (isPaymentRequestData(ev)) {
            applyStyle();
            initializePaymentRequest(ev.data);
            return;
        }

        if (isPaymentRequestUpdate(ev)) {
            paymentRequestUpdatedHandler(ev.data);
            return;
        }

        if (isPaymentRequestFinalize(ev)) {
            await finalizeIntent(ev.data.id, ev.data.secret);
            return;
        }

        if(isIntentChallenged(ev)){
            intentChallengedHandler(ev.data);
            return;
        }

        if (isIntentFailed(ev)) {
            intentFailedHandler(ev.data)
            return;
        }

        if(isMsisdnUpdate(ev)){
            window.parent.postMessage({isUpdateZahlEinfachRequest: true, msisdn: ev.data.msisdn, failureUrl: ev.data.failureUrl}, "*");
            return;
        }

        if(isPaymentCompleted(ev)){
            window.parent.postMessage({finalize: ev.data.finalize, success: ev.data.success}, "*");
            return;
        }
    };

    useEffect( () => {
        if (!stripe) {
            return;
        }

        if (!buttonsComponentPage.brandAllowedPaymentButtons.includes(Buttons.Wallet) || !buttonsComponentPage.allowedPaymentButtons.includes(Buttons.Wallet)) {
            setIsWalletCheckingFinished(true);
            return;
        }

        const pr = stripe.paymentRequest({
            country: buttonsComponentPage.account,
            currency: buttonsComponentPage.currency,
            total: {
                label: buttonsComponentPage.description,
                amount: buttonsComponentPage.amount,
                pending: true
            },
            requestPayerName: true,
            requestPayerEmail: true
        });

        pr.canMakePayment().then((result) => {
            setIsWalletCheckingFinished(true);
            if(result){
                setPaymentRequest(pr);
                setIsWalletAvailable(true);
                setPayment(
                    result.googlePay
                        ? googlePayPaymentButton
                        : result.applePay
                            ? applePayPaymentButton
                            : sessionPaymentButton
                );
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe])

    useEffect( () => {
        if (!stripe) {
            return;
        }

        if (!isWalletCheckingFinished) {
            return;
        }

        window.parent.postMessage({ready: true, account: buttonsComponentPage.account}, "*");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe, isWalletCheckingFinished])

    useLayoutEffect(() => {
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    });

    const onCheckout = () => {
        sessionRequest();
    };

    const onWalletPay = (type) => {
        paymentRequest!.show();
        intentRequest(type);
    };

    const onZahlEinfach = () => {
        const urlParams = new URLSearchParams({
            locale: locale!,
            amount: amount?.toString()!,
            currency: currency!,
            description: encodeURIComponent(description!),
            additional_info: encodeURIComponent(additionalInfo!)
        }).toString();

        popup = window.open("/msisdn?q=" + btoa(urlParams), "zahl-einfach");

        zahlEinfachRequest();
    };

    const intentRequest = (paymentButton) => {
        window.parent.postMessage(
            {
                isRequest: true,
                paymentApi: paymentIntentApi,
                paymentButton: paymentButton,
                context: context
            },
            '*'
        );
    };

    const sessionRequest = () => {
        window.parent.postMessage(
            {
                isRequest: true,
                paymentApi: paymentSessionApi,
                paymentButton: sessionPaymentButton,
                context: context
            },
            '*'
        );
    };

    const zahlEinfachRequest = () => {
        window.parent.postMessage(
            { isRequest: true, paymentButton: ZAHL_EINFFACH_PAYMENT_BUTTON, context: context },
            '*'
        );
    };

    const finalizeIntent = async (intentId, secret) => {
        if (!stripe || !paymentRequest || !secret) {
            return;
        }

        setSecret(secret);
    };

    const setupPaymentMethod = (pr: PaymentRequest) => {
        if (!stripe || !pr) {
            return;
        }

        pr.on('paymentmethod', async (ev) => {

            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                secretRef.current!,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
            );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                window.parent.postMessage(
                    {
                        finalize: true,
                        success: false,
                        errorMessage: 'an error occurred while processing the payment'
                    },
                    '*'
                );
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(secretRef.current!);

                    if (error) {
                        window.parent.postMessage(
                            {
                                finalize: true,
                                success: false,
                                errorMessage: 'an error occurred while processing the payment'
                            },
                            '*'
                        );
                    } else {
                        window.parent.postMessage({ finalize: true, success: true }, '*');
                    }
                } else {
                    window.parent.postMessage({ finalize: true, success: true }, '*');
                }
            }
        });
    }

    const filterAllowedPaymentButtonsWithBrand = (requestedPaymentButtons: Buttons[]) => {
        if (requestedPaymentButtons) {
            let filteredArray = buttonsComponentPage.brandAllowedPaymentButtons.filter((value) =>
                requestedPaymentButtons.includes(value)
            );
            return filteredArray;
        }

        return buttonsComponentPage.brandAllowedPaymentButtons;
    };

    const initializePaymentRequest = (paymentParams: PaymentRequestData) => {
        if (!stripe || !paymentParams) {
            return;
        }

        setLocale(paymentParams.locale);
        setAmount(paymentParams.amount);
        setCurrency(paymentParams.currency);
        setDescription(paymentParams.description);
        setAdditionalInfo(paymentParams.additionalInfo);

        i18n.changeLanguage(paymentParams.locale);
        setContext(paymentParams.context);

        if (paymentParams.allowedPaymentButtons) {
            setPaymentButtons(filterAllowedPaymentButtonsWithBrand(paymentParams.allowedPaymentButtons));
        } else {
            setPaymentButtons(buttonsComponentPage.brandAllowedPaymentButtons);
        }

        if(paymentButtonsRef.current?.includes(Buttons.Wallet) && isWalletAvailable){
            try {
                if (!paymentRequestRef.current) {
                    return;
                }

                setupPaymentMethod(paymentRequestRef.current);
                setLayoutReady(true);
            } catch (err) {
                console.error('Failed to init stripe');
            }
        }else{
            setLayoutReady(true);
        }
    };

    const paymentRequestUpdatedHandler = (ev: PaymentRequestUpdate) => {
        i18n.changeLanguage(ev.locale);
        setContext(ev.context);
        setLocale(ev.locale);
        setAmount(ev.amount);
        setCurrency(ev.currency);
        setDescription(ev.description);
        setAdditionalInfo(ev.additionalInfo);

        if (!paymentRequest) {
            return;
        }

        paymentRequest?.update({
            total: {
                amount: ev.amount,
                label: ev.description
            },
            currency: ev.currency
        });
    };

    const intentChallengedHandler = (ev: IntentChallenged) => {
        if(popup){
            popup.location.href = ev.intentChallengeUrl;
        }
    }

    const  intentFailedHandler = (ev: IntentFailed) => {
        if (!popup) {
            return;
        }

        if (ev.closeWindow) {
            popup.close();
            return;
        }

        popup.location.href = ev.redirectUrl;
    }

    const isWalletAllowed = () => {
        return paymentRequestRef.current && paymentButtonsRef.current && paymentButtonsRef.current.includes(Buttons.Wallet);
    };

    const isZahlEinfachAllowed = () => {
        return paymentButtonsRef.current && paymentButtonsRef.current.includes(Buttons.ZahlEinfach);
    };

    const applyStyle = () => {
        let defaultBrandStyle: CSSProperties = {};

        if (buttonsComponentPage.presentationSettings.branding.button_color !== '') {
            defaultBrandStyle.backgroundColor = buttonsComponentPage.presentationSettings.branding.button_color;
            setStyle(defaultBrandStyle);
        }
    };

    return (
        <div>
            {layoutReadyRef.current && isWalletCheckingFinished &&(
                <Grid className="checkout-buttons" container spacing={1}>
                    <Grid item xs>
                        <Button style={{...style}} onClick={() => onCheckout()}>
                            {t("checkout")}
                        </Button>
                    </Grid>

                    {isWalletAllowed() && (
                        <Grid item xs>
                            <Button style={{...style}} onClick={() => onWalletPay(payment)}>
                                <img src={`/assets/${payment}.svg`} alt="Payment Logo" />
                            </Button>
                        </Grid>
                    )}

                    {isZahlEinfachAllowed() && (
                        <Grid item xs>
                            <Button className="ze-button" style={{...style}} onClick={() => onZahlEinfach()}></Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
}