import React, {MutableRefObject, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Instrument } from "src/features/enterprise/instruments/types/Instrument";
import { CreateInstrumentRequest, CreateInstrumentResponse, Nullable } from "../workers/types";
import 'src/features/enterprise/styles/enterprise.css'
import config from "src/config";
import i18n from "i18next"
import { Button, CircularProgress, ClickAwayListener, Grid, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { dispatch } from "src/workers/common-worker";
import { Elements, PaymentElement, EmbeddedCheckoutProvider, EmbeddedCheckout, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentWalletOption, Stripe, loadStripe } from "@stripe/stripe-js";
import { PresentationSettings } from "src/domain/PresentationSettings";

const paymentsServiceUrl: string =  config.apis.paymentServiceUrl
const tokenMediatorUrl: string =  config.apis.tokenMediatorUrl
let locale: string = "";
let presentationSettings: PresentationSettings | undefined = undefined;
let instrumentTypes: string[] | undefined = undefined;

type RedirectUrl = {
    url?: string | undefined
}

type InstrumentHs = {
    clientSecret?: string | undefined
    stripePromise?: Promise<Stripe | null> | null
    url?: string | undefined
}

export default function PaymentSettingsForm() {
    const [directDebitInstrument, setInstrument] = useState<Nullable<Instrument>>(null);
    const [tenantId, setTenantId] = useState<string>();
    const [customerId, setCustomerId] = useState<string>();
    const [creatingInstrument, setCreatingInstrument] = useState<Map<string, boolean>>();
    const [creatingCard, setCreatingCard] = useState<boolean>(false);
    const [creatingDebit, setCreatingDebit] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>|null>(null);
    const [redirectUrl, setRedirectUrl] = useState<RedirectUrl>()
    const [instrumentHs, setInstrumentHs] = useState<InstrumentHs>()
    const [brandID, setBrandID] = useState<string>("")
    const [gatewayAccount, setGatewayAccount] = useState("")

    const [showDirectDebitOptions, setShowDirectDebitOptions] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    const [testMode, setTestMode] = useState<boolean>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const stripeElementOptions = {
        clientSecret,
        appearance: { 
            variables: {
                fontFamily: 'Roboto, Ideal Sans, system-ui, sans-serif',
                colorText: presentationSettings?.branding.button_color,
                colorTextPlaceholder:'#757680',
                spacingUnit: '4px',
                borderRadius: '4px',
            }
        }
    }
    
    useLayoutEffect( () => {    
        document.body.style.backgroundColor = presentationSettings?.branding.background_color || "#f5f5f5";
        document.title = presentationSettings?.display_name || "1Global";
        loadFavicon(presentationSettings?.assets.favicon);
    })

    const worker: Worker = useMemo(
        () => new Worker(new URL("src/features/enterprise/instruments/workers/create-instrument-worker.ts", import.meta.url)),
        []
    );

    const hsWorker: Worker = useMemo(
        () => new Worker(new URL("src/features/enterprise/instruments/workers/create-instrument-hs-worker.ts", import.meta.url)),
        []
    );

    useEffect(() => {
        let url = `/invoices?q=` + encodeURIComponent(location.state.q);

        setCustomerId(location.state.c);
        setTestMode(location.state.tm === "true");
        setTenantId(location.state.t);
        setInstrument(location.state.i);
        setBrandID(location.state.b);
        setRedirectUrl({ url: url})
        setInstrumentHs({ stripePromise: stripePromise, clientSecret: clientSecret, url: url})
        setGatewayAccount(location.state.ga);
        presentationSettings = location.state.ps;
        instrumentTypes = location.state.it;
        async function setLocale() {
            locale = getLocale();
            await i18n.changeLanguage(locale.replace("-", "_"));
        }
        setLocale();

        setCreatingInstrument(new Map(instrumentTypes?.map<[string, boolean]>(t => [t, false])))

    }, [location, stripePromise, clientSecret])

    if(tenantId === undefined || tenantId === ""){
        return <div></div>;
    }

    const setPaymentInstrumentClicked = async (instrumentType: string) => {
        setCreatingInstrument(new Map(instrumentTypes?.map<[string, boolean]>(t => [t, t === instrumentType])));
        await createInstrument(worker, customerId || "", instrumentType, tenantId, brandID)
        .then((data)=> {
            setCreatingInstrument(new Map(instrumentTypes?.map<[string, boolean]>(t => [t, false])))
            if (data.client_secret === undefined || data.client_secret === "") {
                const errorMsg = t("errorMessage");
                setErrorMessage(errorMsg);
                return;
            } 
            const pKey = testMode ? config.gateway.testApiKeys[gatewayAccount] : config.gateway.apiKeys[gatewayAccount];
            setStripePromise(loadStripe(pKey));
            setClientSecret(data.client_secret);
        })
    }

    const setDebitInstrumentClicked = async (instrumentType: string) => {
        setCreatingInstrument(new Map(instrumentTypes?.map<[string, boolean]>(t => [t, t === instrumentType])));
        setCreatingDebit(true);
        await createHostedSession(hsWorker, customerId || "", tenantId, brandID, locale)
            .then((data) => {
                setCreatingInstrument(new Map(instrumentTypes?.map<[string, boolean]>(t => [t, false])))
                if (data.client_secret === undefined || data.client_secret === "") {
                    const errorMsg = t("errorMessage");
                    setErrorMessage(errorMsg);
                    return;
                }
                const pKey = testMode ? config.gateway.testApiKeys[gatewayAccount] : config.gateway.apiKeys[gatewayAccount];
                setStripePromise(loadStripe(pKey));
                setClientSecret(data.client_secret);
            })
    }

    const isCreatingAny = (): boolean | undefined => {
        let isCreating = false;
        for (let entry of Array.from(creatingInstrument!)) {
            isCreating = isCreating || entry[1]
            if (isCreating) {
                return isCreating;
            }
        }
        return isCreating
    }

    const hasDebitInstrument = (instrumentTypes: string[] | undefined): boolean => {
        if(instrumentTypes === undefined) {
            return false;
        }

        for (let type of instrumentTypes) {
            if (type.includes("debit")){
                return true
            }
        }
        return false
    }

    return (
        <div style={{color: presentationSettings?.branding.button_color}} className="page">
            <div className="page-header"><div>{customerId}</div></div>
            { (clientSecret === "" || clientSecret === undefined) &&
                <Grid container direction="row" justifyContent="center" style={{marginTop: "8px"}}>
                    <Grid style={{height:"37.6px"}} item xs={12} sm={12} md={8} className="xs-centered" container alignItems="center" justifyContent="space-between">
                        <div style={{maxHeight: "30px", display: "flex", alignItems: "center", marginBottom: "30px"}}>
                            <div id="backButton" style={{minHeight: "30px", display: "flex", alignItems: "center", float:"left", marginRight: "12px", cursor: "pointer"}} onClick={() => navigate(-1)}>
                                <svg width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z"></path></svg>
                            </div>
                            <div id="logo" style={{float: "left"}}>
                                <img className="branding-logo" width="auto" height="auto" src={presentationSettings?.assets.logo} alt="brandingLogo"></img>
                            </div>
                            <div id="backLabel" style={{float: "left", fontWeight:500, fontSize: "14px"}}>
                                <span>{t("back")}</span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} className="xs-centered page-content">
                        <h1 style={{textAlign: "left"}}>{t("paymentSettings")}</h1>
                        <div className="mainPanel">
                            <table style={{paddingTop: "24px", width: "100%"}}>
                                <tbody>
                                    { hasDebitInstrument(instrumentTypes) &&
                                    <tr>
                                        <td style={{width: "35%"}}>
                                            <Button className="defaultButton"
                                                    style={{backgroundColor: presentationSettings?.branding.button_color, width:"90%"}}
                                                    disabled={isCreatingAny()}
                                                    onClick={ (e) => setDebitInstrumentClicked("debit") }>
                                                {!creatingInstrument?.get("debit") &&
                                                    <span>
                                                        {directDebitInstrument==null || !directDebitInstrument.type?.includes("debit") ? t('activateDirectDebit') : t('updateDirectDebit')}
                                                    </span>
                                                }
                                                {creatingInstrument?.get("debit") &&
                                                    <div>
                                                        <CircularProgress size="1.1rem" style={{'color': 'white'}}/>
                                                    </div>
                                                }
                                            </Button>
                                        </td>
                                        <td>
                                            { (directDebitInstrument === undefined || !directDebitInstrument?.type?.includes("debit") || directDebitInstrument?.last4 === undefined) &&
                                                <span>{i18n.t('noActiveDirectDebit')}</span>
                                            }
                                            { (directDebitInstrument !== undefined && directDebitInstrument?.last4 !== undefined && directDebitInstrument?.type?.includes("debit")) &&
                                                <div>
                                                    <Row field={i18n.t('account')+" : "} value={"**** " + directDebitInstrument.last4}></Row>
                                                    <Row field={i18n.t('activeSince')+" : "} value={directDebitInstrument.createdStamp?.toLocaleDateString(locale).replace(",", "")}></Row>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    }
                                    { ((instrumentTypes?.includes("card") && directDebitInstrument == null) || directDebitInstrument?.type === "card") &&
                                        <tr>
                                            <td style={{paddingTop: "24px", width: "35%"}}>
                                                <Button className="defaultButton"
                                                        style={{backgroundColor: presentationSettings?.branding.button_color, width:"90%"}}
                                                        disabled= {isCreatingAny()}
                                                        onClick={ (e) => setPaymentInstrumentClicked("card") }>
                                                    {!creatingCard &&
                                                        <span>
                                                            {directDebitInstrument==null ? t('saveCardDetails') : t('updateCardDetails')}
                                                        </span>
                                                    }
                                                    {creatingCard &&
                                                        <div>
                                                            <CircularProgress size="1.1rem" style={{'color': 'white'}}/>
                                                        </div>
                                                    }
                                                </Button>
                                            </td>
                                            <td style={{paddingTop: "24px"}}>
                                                { (directDebitInstrument === undefined || directDebitInstrument?.last4 === undefined) &&
                                                    <span>{i18n.t('noActiveCard')}</span>
                                                }
                                                { (directDebitInstrument !== undefined && directDebitInstrument?.last4 !== undefined) &&
                                                    <div>
                                                        <Row field={i18n.t('account')+" : "} value={"**** " + directDebitInstrument.last4}></Row>
                                                        <Row field={i18n.t('activeSince')+" : "} value={directDebitInstrument.createdStamp?.toLocaleDateString(locale).replace(",", "")}></Row>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    }
                                    {
                                        errorMessage !== "" &&
                                        <tr>
                                            <td style={{paddingTop: "24px"}}>
                                                <span style={{color: "red"}}>{errorMessage}</span>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            }
            { clientSecret !== "" && clientSecret !== undefined &&
                <Grid style={{marginTop:"0px"}} container spacing={1} direction="row" justifyContent="center">
                    <Grid item xs={12} sm={6} md={4} style={{position: "relative"}}>
                        <div style={{maxHeight: "30px", display: "flex", alignItems: "center", marginBottom: "30px"}}>
                            <div id="backButton" style={{minHeight: "30px", display: "flex", alignItems: "center", float:"left", marginRight: "12px", cursor: "pointer"}} onClick={() => navigate(-1)}>
                                <svg width="12" height="12" viewBox="0 0 16 16"><path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z"></path></svg>
                            </div>
                            <div id="logo" style={{float: "left"}}>
                                <img className="branding-logo" width="auto" height="auto" src={presentationSettings?.assets.logo} alt="brandingLogo"></img>
                            </div>
                            <div id="backLabel" style={{float: "left", fontWeight:500, fontSize: "14px"}}>
                                <span>{t("back")}</span>
                            </div>
                        </div>
                        { !creatingDebit &&
                            <div className="lg-footer" style={{ width: "100%", textAlign: "center", bottom: "0px", position: "absolute"}}>
                                <a className="dottedLink" href="https://stripe.com/legal/end-users">{t("terms")}</a>
                                <a className="dottedLink" href="https://stripe.com/privacy">{t("privacy")}</a>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        { creatingDebit &&
                            <DirectDebitInstrument {...instrumentHs}></DirectDebitInstrument>
                        }
                        { !creatingDebit &&
                            <Elements options={stripeElementOptions} stripe={stripePromise}>
                                <CardInstrument {...redirectUrl }></CardInstrument>
                            </Elements>
                        }
                    </Grid>
                </Grid>
            }
        </div>
    );
}

function Row({field, value}: {field: string, value: string | undefined}) {
    return (
        <div className="row">
            <div className="column left-column">{field}</div>
            <div className="column right-column">{value}</div>
        </div>
    )
}

function DirectDebitInstrument(instrumentHs: InstrumentHs) {
    const navigate = useNavigate();

    const options = {
        clientSecret: instrumentHs.clientSecret ?? "",
        onComplete: () => redirect()
    };

    let redirect = function () {
        navigate(instrumentHs.url ?? "", {state: {instrumentSetup: true}});
    }

    return (
        <div>
            <EmbeddedCheckoutProvider
                options={options}
                stripe={instrumentHs.stripePromise!}>
                <EmbeddedCheckout/>
            </EmbeddedCheckoutProvider>
        </div>
    )

}

function CardInstrument(redirectUrl: RedirectUrl) {
    const elements = useElements()
    const stripe = useStripe()
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const submitInstrument = () => {
        setIsSubmiting(true);
        if (!stripe || !elements) {
            return
        }
        stripe.confirmSetup({
            elements,
            redirect: "if_required",
            confirmParams: {
                 return_url: window.location.protocol + "//" + window.location.host + redirectUrl.url + "&instrument_setup=succeed" ?? "",
            },
        }).then((value) => {
            setIsSubmiting(false);

            if (value.error === undefined) {
                navigate(redirectUrl.url ?? "", {state: {instrumentSetup: true}})
            }  else {
                if (value.error?.type === "card_error" || value.error?.type === "validation_error") {
                    setErrorMessage(value.error?.message ?? '');
                } else {
                    const errorMsg = t("errorMessage");
                    setErrorMessage(errorMsg);
                }
            }
        }).catch((error) => { 
            const errorMsg = t("errorMessage");
            setErrorMessage(errorMsg);
            console.log(error);
            setIsSubmiting(false);
        });
    }

    const options = {
        wallets: {
            applePay: ('never' as PaymentWalletOption),
            googlePay: ('never' as PaymentWalletOption)
        }
    };

    return (
        <div>
        <PaymentElement id="payment-element" options={options}></PaymentElement>
            {errorMessage &&
                <div style={{ marginTop: '15px', color: '#dc2727'}}>{errorMessage}</div>
            }
            <div style={{ marginTop: "20px" }}>
                <button className="defaultButton center wide" style={{backgroundColor: presentationSettings?.branding.button_color}} onClick={submitInstrument}>
                    {!isSubmiting&&<span>{t("submit")} </span>}
                    {isSubmiting && <CircularProgress size="1.75rem" style={{'color': 'white'}}/>}
                </button>
            </div>
        </div>
    )
}

async function createInstrument(worker: Worker, customerId: string, instrumentTypes: string, tenantId: string, brandId: string) {
    const request: CreateInstrumentRequest = {
        tokenMediatorUrl: tokenMediatorUrl,
        paymentsServiceUrl: paymentsServiceUrl,
        customerId: customerId,
        instrumentType: instrumentTypes,
        tenantId: tenantId,
        brandId: brandId,
    };

    var response: Nullable<CreateInstrumentResponse> = {} as CreateInstrumentResponse

    await dispatch<CreateInstrumentRequest, CreateInstrumentResponse>(worker, request)
        .then((data: Nullable<CreateInstrumentResponse>) => {response = data})

    return response;
}

async function createHostedSession(worker: Worker, customerId: string, tenantId: string, brandId: string, locale: string) {
    const request: CreateInstrumentRequest = {
        tokenMediatorUrl: tokenMediatorUrl,
        paymentsServiceUrl: paymentsServiceUrl,
        customerId: customerId,
        tenantId: tenantId,
        brandId: brandId,
    };

    var response: Nullable<CreateInstrumentResponse> = {} as CreateInstrumentResponse

    await dispatch<CreateInstrumentRequest, CreateInstrumentResponse>(worker, request)
        .then((data: Nullable<CreateInstrumentResponse>) => {response = data})

    return response;
}

function getLocale() {
    let locale = window.navigator.language;

    if(locale !== null && locale !== undefined && locale !== "") {
        if (locale === "zh") {
            locale = "zh_Hans";
        }
        if (locale === "zh-HK") {
            locale = "zh_Hant_HK";
        }
        if (locale === "nb") {
            locale = "nb_NO";
        }
    } else {
        locale = "en";
    }

    return locale;
}

const loadFavicon = (faviconLink: string | undefined) => {
    if (faviconLink === undefined) {
        return;
    }
    
    const link = document.createElement('link');
    link.id = 'favicon';
    link.rel = 'shortcut icon';
    document.head.appendChild(link);

    (link as HTMLLinkElement).href = faviconLink;
}